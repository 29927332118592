import React from 'react';
// Structure imports
import Layout from '@components/structure/Layout';
import Seo from '@components/utility/SEO';
import Hero from '@components/content/hero/Hero';
import Image from '@components/utility/Image';
import './styles.scss';

const NotFound = () => {
	return (
		<Layout>
			<Seo title="404 Page Not Found | EMFLAZA® (deflazacort)"/>
			<div className='relative min-h-[90vh] md:min-h-[50vh] lg:min-h-[35vh] xl:min-h-[90vh] page--404'>
				<Hero
					heroImage='bkg-rectangle.png'
					heroImageMobile='bkg-rectangle-mobile.png'
					title={
						<span className='text-6xl md:text-8xl'>
							<span className='inline-block'>404</span>
							<br />
							<span className='inline-block text-4xl md:text-7xl'>Page not found</span>
						</span>
					}
					addedClass='blue-bg'
					useBreadcrumbs={false}
				/>
				<div className='pt-12 xl:mb-56 lg:pt-0'>
					<div className=' sm:max-w-2xl'>
						<p className='px-8 text-sm font-normal text-left lg:px-20 font-roboto'>
							The page you were looking for could not be found. It might
							have been removed, renamed, or did not exist in the first
							place.
						</p>
					</div>
					<div className='hidden xl:block right-0 -bottom-0 absolute'>
						<Image imageName='404-500-anchored-deco.png'></Image>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default NotFound;
